<template>
  <v-list-item :href="href" :data-testid="testId">
    <v-list-item-content>
      <v-list-item-title>
        <span v-if="badge === null">{{ formattedTitle }}</span>
        <v-badge v-else color="secondary" :content="badge">{{ formattedTitle }}</v-badge>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    nested: Boolean,
    badge: String,
    title: String,
    href: String,
    category: String,
  },
  computed: {
    formattedTitle() {
      return this.nested ? `${this.category} › ${this.title}` : this.title
    },
    testId() {
      return `mobile-menu_${this.formattedTitle}`
    },
  },
}
</script>

<style lang="scss">
$grey: #eeeeee;

.v-list-group__header {
  background-color: $grey;
}
</style>
